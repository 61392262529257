import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import Image from "../../components/image/image";
import { GetSocialMediaLinks } from "../../queries/query-social-media";
import { currentLocale } from "../../../src/i18n/locale-provider";
import { prependLocale } from "../../../src/i18n/prepend-locale";
import {
  content,
  logo,
  icon,
  stamp,
  info,
  copyright,
  socialMedia,
  textElems,
} from "./footer.module.scss";
import useLocalizedData from "../../i18n/use-localized-data";
import { GetNavigationItemsFiltered } from "../../queries/query-nav-filtered";

const Footer = ({ data }) => {
  const socialMediaLinks = GetSocialMediaLinks();
  const navData = useLocalizedData(GetNavigationItemsFiltered());

  return (
    <footer id="footer" className={`footer`}>
      <div className={content}>
        {data.Footer.Logo ? (
          <img
            loading="lazy"
            className={logo}
            src={data.Footer.Logo.url}
            alt="Esportido"
          />
        ) : null}
        <div className={textElems}>
          {data.Footer.CompanyText ? (
            <ReactMarkdown className={info}>
              {data.Footer.CompanyText.data.CompanyText}
            </ReactMarkdown>
          ) : null}
          {navData.map((item) => (
            <div key={item.id} className={info}>
              <h5>{item.Name}</h5>
              {item.Children?.map((child) => (
                <p key={child.id}>
                  <Link to={prependLocale(child.Link)}>{child.Name}</Link>
                </p>
              ))}
            </div>
          ))}
          <div className={info}>
            <h5>{currentLocale === "de" ? "Rechtliches" : "Legal"}</h5>
            <p>
              <Link to={prependLocale("/terms-of-use/")}>
                {currentLocale === "de"
                  ? "Nutzungsbedingungen"
                  : "Terms of Use"}
              </Link>
            </p>
            <p>
              <Link to={prependLocale("/imprint/")}>
                {currentLocale === "de" ? "Impressum" : "Imprint"}
              </Link>
            </p>
            <p>
              <Link to={prependLocale("/data-protection/")}>
                {currentLocale === "de" ? "Datenschutz" : "Data Protection"}
              </Link>
            </p>
            <p>
              <Link to={prependLocale("/disclaimer/")}>
                {currentLocale === "de"
                  ? "Haftungsausschluss"
                  : "Legal Disclaimer"}
              </Link>
            </p>
          </div>
          <div className={info}>
            <h5>{currentLocale === "de" ? "Folge uns" : "Follow us"}</h5>
            <div className={socialMedia}>
              {socialMediaLinks?.map((entry) => (
                <a key={entry.Url} href={entry.Url} target="blank">
                  <Image gatsbyImage={entry.Icon} className={icon} />
                </a>
              ))}
            </div>
          </div>
        </div>
        {data.Footer.GdprLogo ? (
          <Image gatsbyImage={data.Footer.GdprLogo} className={stamp} />
        ) : null}
        <p className={copyright}>© 2023 esportido AG</p>
      </div>
    </footer>
  );
};

export default Footer;
