import React, { useEffect, useState, useContext } from "react";
import {
  nav,
  navItemList,
  open,
  sticky,
  linkC,
  active,
  dropdown,
  linkWrapper,
  dropdownItems,
  languages,
  abbrevation,
  activeLocale,
  socialMedia,
  icon,
} from "./navigation.module.scss";
import { Link } from "gatsby";
import { globalHistory, useLocation } from "@reach/router";
import arrowDownIconLight from "../../assets/icons/arrow_down.svg";
import arrowDownIconDark from "../../assets/icons/arrow_down_dark.svg";
import { locales, defaultLocale } from "../../i18n/i18n-config";
import { LocaleContext, currentLocale } from "../../i18n/locale-provider";
import useLocalizedData from "../../i18n/use-localized-data";
import { GetSocialMediaLinks } from "../../queries/query-social-media";
import { GetNavigationItems } from "../../queries/query-nav";
import Image from "../../components/image/image";
import { prependLocale } from "../../i18n/prepend-locale";

let isMobile = true;

const Navigation = ({ isOpen, isSticky, close }) => {
  const socialMediaLinks = GetSocialMediaLinks();
  const navData = useLocalizedData(GetNavigationItems());
  const location = useLocation();
  const [navItems, setNavItems] = useState();
  const [localeButtons, setLocaleButtons] = useState([]);
  const { locale } = useContext(LocaleContext);

  const onNavClick = () => {
    close();
  };

  const onMouseOver = (item) => {
    if (!isMobile) {
      return;
    }
    toggleDropdownItem(item);
  };

  const onDropdownToggle = (item, event) => {
    if (item.children?.length === 0 || isMobile) {
      return;
    }
    event.stopPropagation();
    toggleDropdownItem(item, true);
  };

  const toggleDropdownItem = (toggleItem, closeOthers = false) => {
    const newItems = [].concat(navItems);
    newItems.forEach((item, index) => {
      if (item?.id === toggleItem.id) {
        item.open = !item.open;
        const dropdown = document.getElementById(`item-${index}`);
        item.dropdownHeight = item.open ? dropdown?.offsetHeight : 0;
      }
      if (closeOthers && item?.id !== toggleItem.id) {
        item.open = false;
        item.dropdownHeight = 0;
      }
    });
    setNavItems(newItems);
  };

  const updateLinks = (path, items) => {
    if (!items) {
      items = [].concat(navItems);
    }
    items.forEach((item) => {
      item.active = item.to === path;
      item.children?.forEach((child) => {
        child.active = child.to === path;
        if (child.active) {
          item.active = true;
          if (!item.open && !isMobile) {
            toggleDropdownItem(item);
          }
        }
      });
    });
    setNavItems(items);
  };

  const prepareNavItem = (data) => {
    return {
      id: data.id,
      name: data.Name,
      to: data.Link,
      children: [],
      dropdownHeight: 0,
      active: false,
    };
  };

  const getPath = () => {
    return (window.location.pathname + window.location.hash).replace(
      "/" + currentLocale,
      "",
    );
  };

  useEffect(() => {
    isMobile = window.matchMedia("(min-width: 992px)")?.matches;
    const preparedNav = [];
    navData.forEach((item) => {
      const newItem = prepareNavItem(item);
      item.Children?.forEach((child) => {
        newItem.children.push(prepareNavItem(child));
      });
      preparedNav.push(newItem);
    });
    updateLinks(getPath(), preparedNav);
    globalHistory.listen(() => {
      updateLinks(getPath(), preparedNav);
    });
  }, []);

  useEffect(() => {
    let pathname = location.pathname;
    // Remove all locale paths from current pathname
    locales.forEach((l) => (pathname = pathname.replace(`/${l}/`, "/")));
    setLocaleButtons(
      locales.map((l) => {
        if (defaultLocale === l) {
          return { locale: l, link: `//${location.host}${pathname}` };
        }
        return {
          locale: l,
          link: `//${location.host}/${l}${pathname}`,
        };
      }),
    );
  }, [location]);

  return (
    <nav
      className={`${nav} ${isOpen ? `${open}` : ""} ${isSticky ? sticky : ""}`}
      onClick={() => onNavClick()}
      onKeyDown={() => close()}
    >
      <div id="nav-items" className={`${navItemList}`}>
        {navItems?.map((item, index) => (
          <div
            key={item.id}
            className={`${linkWrapper} ${item.active ? active : ""}`}
            onMouseOver={(event) => onMouseOver(item)}
            onMouseOut={(event) => onMouseOver(item)}
          >
            {item.children?.length > 0 ? (
              <button
                className={`${linkC} ${item.open ? open : ""} ${
                  item.active ? active : ""
                }`}
                onClick={(event) => onDropdownToggle(item, event)}
              >
                <span>{item.name}</span>
                {item.children?.length > 0 ? (
                  <>
                    <img
                      src={arrowDownIconLight}
                      alt="Arrow down"
                      className="d-lg-none"
                    />
                    <img
                      src={arrowDownIconDark}
                      alt="Arrow down"
                      className="d-none d-lg-block"
                    />
                  </>
                ) : null}
              </button>
            ) : (
              <Link
                to={prependLocale(item.to)}
                className={`${linkC} ${item.active ? active : ""}`}
              >
                <span>{item.name}</span>
              </Link>
            )}
            {item.children?.length > 0 ? (
              <div className={dropdown} style={{ height: item.dropdownHeight }}>
                <div id={`item-${index}`} className={dropdownItems}>
                  {item.children.map((child) => (
                    <Link
                      key={child.id}
                      to={prependLocale(child.to)}
                      className={`${linkC} ${child.active ? active : ""}`}
                    >
                      <span>{child.name}</span>
                    </Link>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        ))}
        <div className={languages}>
          {localeButtons.map((button, index) => (
            <div className={abbrevation} key={button.locale}>
              <a
                className={button.locale === locale ? activeLocale : ""}
                href={button.link}
                onClick={(event) => event.stopPropagation()}
              >
                {button.locale}
              </a>
              {index < locales.length - 1 ? <span>/</span> : null}
            </div>
          ))}
        </div>
        <div className={socialMedia}>
          {socialMediaLinks?.map((entry) => (
            <a key={entry.Url} href={entry.Url} target="blank">
              <Image gatsbyImage={entry.Icon} className={icon} />
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
