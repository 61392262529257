import { graphql, useStaticQuery } from "gatsby";
import useLocalizedData from "../i18n/use-localized-data";

export const GetFooter = () => {
  const siteData = useStaticQuery(graphql`
    query getFooter {
      allStrapiWebsite {
        nodes {
          locale
          Footer {
            CompanyText {
              data {
                CompanyText
              }
            }
            Logo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alternativeText
              url
            }
            GdprLogo {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
              alternativeText
              url
            }
          }
        }
      }
    }
  `);
  return useLocalizedData(siteData.allStrapiWebsite);
};