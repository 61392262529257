import React from "react";
import { LocaleProvider, currentLocale } from "./src/i18n/locale-provider";
import Layout from "./src/components/layout/layout";
import { Provider } from "react-redux";
// import CookieConsent from "./src/components/cookie-consent/cookie-consent";
import store from "./store";

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  const { pathname } = location;
  const finalPath = pathname.replace(currentLocale + "/", "");
  if (finalPath !== "/") {
    setTimeout(() => window.scrollTo(0, 0), 100);
    return false;
  }

  return true;
};

export const wrapPageElement = ({ element, props }) => {
  return (
    <Provider store={store}>
      <LocaleProvider {...props}>
        <Layout>{element}</Layout>
        {/* <CookieConsent /> */}
      </LocaleProvider>
    </Provider>
  );
};
