import { useContext } from "react";
import { LocaleContext } from "./locale-provider";

/**
 * @param data the object returned from the strapi graphql
 * data request for example: "data.allStrapiWebsite"
 * @returns array if collection | object if single type filtered by the current locale
 */
const useLocalizedData = (data) => {
  const { locale } = useContext(LocaleContext);
  if (!data?.nodes?.length) {
    return data;
  }
  // No translations available
  if (data.nodes.length < 2) {
    console.warn("Expected translations but found none!");
    return data.nodes[0];
  }
  const localeData = data.nodes.filter((node) => {
    if (!node.locale) {
      return node;
    }
    return node.locale === locale;
  });
  // Collection
  if (localeData.length > 1) {
    return localeData;
  }
  // Single Type
  return localeData[0];
};

export default useLocalizedData;
