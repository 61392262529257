import { defaultLocale } from "../i18n/i18n-config";
import { currentLocale } from "../i18n/locale-provider";

export const prependLocale = (string, hasStartingSlash = true) => {
  if (!string) {
    return string.replace("//", "/");
  }
  if (currentLocale === defaultLocale) {
    return string.replace("//", "/");
  }
  if (string.startsWith(`/${currentLocale}/`)) {
    return string.replace("//", "/");
  }
  return `${hasStartingSlash ? "/" : ""}${currentLocale}/${string}`.replace(
    "//",
    "/",
  );
};
