import { graphql, useStaticQuery } from "gatsby";

export const GetNavigationItems = () => {
  const siteData = useStaticQuery(graphql`
    query getNavigationItems {
      allStrapiNavigationItem(filter: { Parent: { id: { eq: null } } }) {
        nodes {
          Name
          Link
          locale
          id
          Children {
            id
            Name
            Link
          }
        }
      }
    }
  `);
  return siteData.allStrapiNavigationItem;
};
