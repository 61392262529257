// extracted by mini-css-extract-plugin
export var content = "footer-module--content--90752";
export var copyright = "footer-module--copyright--060f5";
export var dBlock = "footer-module--d-block--7cddf";
export var dFlex = "footer-module--d-flex--43309";
export var dInline = "footer-module--d-inline--fdaba";
export var dLgBlock = "footer-module--d-lg-block--271dc";
export var dLgFlex = "footer-module--d-lg-flex--333ba";
export var dLgInline = "footer-module--d-lg-inline--6bb86";
export var dLgNone = "footer-module--d-lg-none--c3edb";
export var dMdBlock = "footer-module--d-md-block--18441";
export var dMdFlex = "footer-module--d-md-flex--56b22";
export var dMdNone = "footer-module--d-md-none--f0d4f";
export var dNone = "footer-module--d-none--83a31";
export var icon = "footer-module--icon--82bee";
export var info = "footer-module--info--c8d02";
export var logo = "footer-module--logo--e67c9";
export var socialMedia = "footer-module--socialMedia--6675a";
export var stamp = "footer-module--stamp--399d4";
export var textElems = "footer-module--textElems--a1f01";