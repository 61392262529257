import React, { useEffect, useState } from "react";
import "../../styles/styles.scss";
import Header from "../header/header";
import Footer from "../footer/footer";
import Pattern from "../pattern/pattern";
import { GetFooter } from "../../queries/query-footer";
import arrowDownIcon from "../../assets/icons/arrow_down_white.svg";
import { currentLocale } from "../../i18n/locale-provider";

const Layout = ({ children }) => {
  const [navOpen, setNavOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const footerData = GetFooter();

  const onScrollTop = () => {
    window.scrollTo(0, 0);
  };

  const toggleNavOpen = (value) => {
    setNavOpen(value);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setIsScrolled(window.scrollY > 100);
    });
  }, []);

  return (
    <>
      <Header setNavOpen={toggleNavOpen} />
      <main className={`content ${navOpen ? "hidden" : ""}`}>
        {children}
        <Footer data={footerData} />
        <button
          className={`goTop ${isScrolled ? "" : "goTopHidden"}`}
          onClick={() => onScrollTop()}
        >
          <img src={arrowDownIcon} alt="Arrow up" />
          {currentLocale === "de" ? "Nach oben" : "Back to top"}
        </button>
      </main>
      <Pattern />
    </>
  );
};

export default Layout;
