// extracted by mini-css-extract-plugin
export var abbrevation = "navigation-module--abbrevation--1be1f";
export var active = "navigation-module--active--f4655";
export var activeLocale = "navigation-module--activeLocale--6c263";
export var dBlock = "navigation-module--d-block--77885";
export var dFlex = "navigation-module--d-flex--45e48";
export var dInline = "navigation-module--d-inline--4e590";
export var dLgBlock = "navigation-module--d-lg-block--74f03";
export var dLgFlex = "navigation-module--d-lg-flex--5bb35";
export var dLgInline = "navigation-module--d-lg-inline--e1528";
export var dLgNone = "navigation-module--d-lg-none--078e0";
export var dMdBlock = "navigation-module--d-md-block--e44a0";
export var dMdFlex = "navigation-module--d-md-flex--397c6";
export var dMdNone = "navigation-module--d-md-none--b8926";
export var dNone = "navigation-module--d-none--d4199";
export var dropdown = "navigation-module--dropdown--59125";
export var dropdownItems = "navigation-module--dropdownItems--4ba36";
export var icon = "navigation-module--icon--6af00";
export var languages = "navigation-module--languages--7c284";
export var linkC = "navigation-module--linkC--fc6d5";
export var linkWrapper = "navigation-module--linkWrapper--a7c96";
export var nav = "navigation-module--nav--3fb10";
export var navItemList = "navigation-module--navItemList--01a9f";
export var open = "navigation-module--open--78888";
export var socialMedia = "navigation-module--socialMedia--d8e70";
export var sticky = "navigation-module--sticky--14aa3";