import React, { useEffect } from "react";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";
import { pattern } from "./pattern.module.scss";
import patternImage from "../../assets/pattern.svg";

const Pattern = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const className = "." + pattern;
    gsap.to(className, {
      scrollTrigger: {
        scrub: 0,
        end: "+=10000px",
      },
      ease: "none",
      translateY: `50%`,
      translateX: `-50%`,
    });
  }, []);

  return (
    <img
      src={patternImage}
      alt="Background pattern"
      className={pattern}
      loading="lazy"
    />
  );
};

export default Pattern;
