// extracted by mini-css-extract-plugin
export var dBlock = "pattern-module--d-block--c8b01";
export var dFlex = "pattern-module--d-flex--45ead";
export var dInline = "pattern-module--d-inline--fa7bf";
export var dLgBlock = "pattern-module--d-lg-block--2603c";
export var dLgFlex = "pattern-module--d-lg-flex--445e2";
export var dLgInline = "pattern-module--d-lg-inline--a7e2b";
export var dLgNone = "pattern-module--d-lg-none--438fd";
export var dMdBlock = "pattern-module--d-md-block--7112e";
export var dMdFlex = "pattern-module--d-md-flex--e37f7";
export var dMdNone = "pattern-module--d-md-none--e450e";
export var dNone = "pattern-module--d-none--e997b";
export var pattern = "pattern-module--pattern--b299c";