import { graphql, useStaticQuery } from "gatsby";

export const GetSocialMediaLinks = () => {
  const siteData = useStaticQuery(graphql`
    query getSocialMediaLinks {
      strapiWebsite {
        SocialMedia {
          Icon {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
            alternativeText
            url
          }
          Url
        }
      }
    }
  `);
  return siteData.strapiWebsite.SocialMedia;
};