import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import logoImage from "../../assets/logo.png";
import { prependLocale } from "../../../src/i18n/prepend-locale";
import {
  logo,
  header,
  headerContent,
  menuToggle,
  navOpen,
  sticky,
} from "./header.module.scss";
import Navigation from "../navigation/navigation";

let isMobile = false;

const Header = ({ setNavOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const close = () => {
    if (isMobile) {
      return;
    }
    const scrollY = document.body.style.top;
    document.body.style.position = "";
    document.body.style.top = "";
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo(0, parseInt(scrollY || "0") * -1);
    document.documentElement.style.scrollBehavior = "";
    setIsOpen(false);
    setNavOpen(false);
  };

  const open = () => {
    if (isMobile) {
      return;
    }
    const scrollY = window.scrollY;
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollY}px`;
    setIsOpen(true);
    setNavOpen(true);
  };

  const toggle = () => {
    isOpen ? close() : open();
  };

  useEffect(() => {
    isMobile = window.matchMedia("(min-width: 992px)")?.matches;
    const headerHeight = getComputedStyle(document.documentElement)
      .getPropertyValue("--header-height")
      ?.replace("px", "");
    setIsScrolled(window.scrollY > headerHeight / 2);
    window.addEventListener("scroll", () => {
      if (document.body.style.position !== "fixed") {
        setIsScrolled(window.scrollY > headerHeight / 2);
      }
    });
  }, []);

  return (
    <header className={`${header} ${isScrolled ? sticky : ""}`}>
      <div className={`maxPageWidth ${headerContent}`}>
        <Link to={prependLocale("/")} className={logo} onClick={() => close()}>
          <img src={logoImage} alt="esportido" />
        </Link>
        <button
          onClick={() => toggle()}
          className={`${menuToggle} ${isOpen ? navOpen : ""}`}
          aria-label="Menu toggle"
        >
          <span></span>
        </button>
        <Navigation isOpen={isOpen} isSticky={isScrolled} close={close} />
      </div>
    </header>
  );
};

export default Header;
