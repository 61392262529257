import { graphql, useStaticQuery } from "gatsby";

export const GetNavigationItemsFiltered = () => {
  const siteData = useStaticQuery(graphql`
    query getNavigationItemsFiltered {
      allStrapiNavigationItem(
        filter: {
          Parent: { id: { eq: null } }
          Children: { elemMatch: { id: { ne: null } } }
        }
      ) {
        nodes {
          Name
          Link
          locale
          id
          Children {
            id
            Name
            Link
          }
        }
      }
    }
  `);
  return siteData.allStrapiNavigationItem;
};
